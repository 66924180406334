
:root {
  --ui: #00a5cf;
}


body {
  margin: 0;
  font-family: "Noto Sans", Barlow, Roboto, -apple-system, BlinkMacSystemFont, Arial, "Segoe UI", "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 13px;

  padding: 0;
  border: 0;
  width: 100%;
}



.container {
  background-color: #eeeeee;
  color: black;
  width: calc(100% - 10px);
  min-height: calc(100vh - 10px);
  padding: 5px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 5px;
  font-weight: 400;
}

h1 {
  font-size: 20px;
  font-weight: 300;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 15px;
  font-weight: 500;
}

h6 {
  font-size: 13px;
  font-weight: 500;
}

div {
  font-size: 15px;
}

p {
  font-size: 13px;
}

b {
  font-weight: 500;
}

.card {
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  width: calc(100% - 10px);
  max-width: 500px;
  margin: 10px 0;
  text-align: center;
}

.title {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: none;
}

.title__username {
  margin-right: 10px;
}

.logo__icon {
  width: 35px;
  height: 35px;
  margin: 5px;
}

.logo__box {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: center;
  margin: 3px;
  padding-right: 5px;
  cursor: pointer;
}

.logo__text {
  margin: 0;
  font-weight: 300;
}

.bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stack {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown_label {
  margin-right: 20px;
  font-size: 15px;
}

.dropdown__selector {
  font: inherit;
  background-color: white;
  border: solid 2px #555;
  border-radius: 10px;
  text-align: center;
  margin: 5px;
  font-size: 15px;
  padding: 3px 3px 3px 6px;
  color: black;
}

.dropdown__selector:focus {
  border-color: #00a5cf;
  outline: transparent;
}

.input-text {
  font: inherit;
  background-color: white;
  border: solid 2px #555;
  border-radius: 10px;
  text-align: center;
  margin: 5px;
  padding: 3px;
}
.input-text:focus {
  border-color: #00a5cf;
  outline: transparent;
}

.nav-current {
  text-decoration: underline #00a5cf solid 3px;
  -webkit-text-decoration-style: solid;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #00a5cf;
  text-decoration-thickness: 3px;
}
.nav-notCurrent {
  text-decoration: underline #b0b0b0 solid 3px;
  -webkit-text-decoration-style: solid;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #b0b0b0;
  text-decoration-thickness: 3px;
}
.nav-notCurrent:hover {
  text-decoration: underline #00a5cf solid 3px;
  -webkit-text-decoration-style: solid;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #00a5cf;
  text-decoration-thickness: 3px;
}

.nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.nav-item {
  min-height: 20px;
  font-size: 18px;
  padding: 5px 10px;
  cursor: pointer;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.nav-item:hover {
  background-color: #d0d0d0;
}

.ticket {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ticket-row {
  margin: 5px;
  width: calc(100% - 10px);

}

.add-item-start {
  border: dashed 5px #00a5cf;
  border-radius: 10px;
  height: 100px;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00a5cf;

  margin: 0 10px;
}

.add-item-config {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.item-topBox {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}

.item-name {
  width: 300px;
  flex: 2 1 300px;
  min-width: 50%;
  padding: 3px;
}
.item-price {
  height: fit-content;
  margin: 5px 5px 8px 5px;
  flex: 1 1 55px;
}

.item-sides {
  padding: 3px;

}

.item-notes {
  min-height: 40px;
  font-size: 13px;
  resize: none;
}

.item-buttons {
  display: flex;
  justify-content: center;
}
.button {
  border: solid 2px;
  padding: 5px;
  margin: 5px 10px;
  border-radius: 10px;
  outline: solid 0;
}
.button:hover, .button:active {
  outline-width: 1px;
  border-color: white !important;
}

.button-thin {
  border: solid 2px;
  padding: 3px 5px;
  margin: 0;
  border-radius: 10px;
  outline: solid 0;
  height: fit-content;
  width: fit-content;
}
.button-thin:hover, .button-thin:active {
  outline-width: 1px;
  border-color: white !important;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 5px auto;
  width: fit-content;
  border-radius: 20px;
}

.banner__text {
  margin: 0 5px;
}

.cart-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}

.cart-left {
  flex: 2 1 300px;
}

.cart-name {
  font-size: 18px;
  font-weight: 500;
}

.cart-price {
  flex: 0 0 fit-content;
  min-width: 55px;
  text-align: right;
}

.cart-delete {
  border-radius: 18px;
  background-color: #555555;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 34px;
  flex: 0 1 28px;
  padding: 0 3px;
  border: #555 solid 2px;
}
.cart-delete:hover, .cart-delete:active {
  border-color: white;
  outline: solid 1px #555555;
}

.notice-outer {
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}
.notice-inner {
  overflow-y: scroll;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: #000000b2;
  justify-content: flex-start;
  flex-direction: column;
}
.notice-panel {
  max-width: 500px;
  width: calc(100% - 60px);
  margin: 30px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0;
  border-radius: 10px;
  text-align: center;
}
.notice-top {
  height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: solid 1px #d0d0d0;
}
.notice-close {
  height: 20px;
  width: 20px;
  color: #222222;
  border-radius: 5px;
  border: solid 2px #222222;
  outline: solid 0 transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notice-close:hover, .notice-close:active {
  border-color: transparent !important;
  outline: solid 1px #222222;
}
.notice-body {
  display: flex;
  flex-direction: column;

  margin: 10px;
  text-align: center;
}

.user-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.user-list-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 3px;
}

.badge-box {
  border: 0;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin: 0 2px;
}

.user-add-box {
  display: flex;
  flex-direction: column;
}

.user-add-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.user-add-name {
  flex: 1 0 100px;
}

.user-list-name {
  margin: 2px 5px 5px 5px;
}

.orderDisplay-top {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: left;
  align-items: center;
  padding: 2px 5px;
}
.orderDisplay-cost {
  display: grid;
  padding: 5px 10px;
  grid-template: "a a a"
                 "b c d"
                 "e f g"
                 "h h i";
}
.orderDisplay-pay {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 5px 10px;
}

.top-border {
  border-top: solid 1px #d0d0d0;
}

.card.orderDisplay-box {
  margin: 2px 0;
  padding: 0;
}

.orderDisplay-pay-input {
  width: 100px;
  margin: 2px 5px;
}

.orderDisplay-item {
  margin: 0 10px;
}

.numberBox {
  background-color: #eeeeee;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
}

.grid-box {
  justify-content: center;
  align-items: center;

}
.grid-item {
  height: fit-content;
  margin: 2px 3px;
}
.grid-item-a {
  grid-area: a;
}
.grid-item-b {
  grid-area: b;
}
.grid-item-c {
  grid-area: c;
}
.grid-item-e {
  grid-area: e;
}
.grid-item-f {
  grid-area: f;
}
.grid-item-h {
  grid-area: h;
}

.text-align-right {
  text-align: right;
}

.orderDates {
  display: grid;
  grid-template: auto / auto auto;
  padding: 5px;
}

.main__page {
  min-height: calc(100vh - 205px);
}

.login__title {
  margin: 30px 5px 20px 5px;
  font-size: 25px;
}

.login__button {
  padding: 3px 10px;
  background-color: white;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  margin: 15px 5px;
}

.register__button {
  margin: 50px 5px 10px 5px;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linking-code-input {
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 3px;
  font-weight: 400;
  max-width: calc(100% - 20px);
}

.flat {
  box-shadow: none;
}

.footer-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.links {
  color: black;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.linking-code-copy-box {
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 0 5px;
}

.linking-code-copy-box:active {
  background-color: #00a5cf;
  color: white;
}

.block-of-text {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 500px;
}

.subheading {
  margin: 20px 5px 0px 5px;
}

.reload-button {
  background-color: white;
  height: 20px;
  width: 20px;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 0 0 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.reload-button:active {
  background-color: #00a5cf;
}